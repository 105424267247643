import { createTheme, Theme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    darkBackground: React.CSSProperties["color"];
  }

  interface PaletteOptions {
    darkBackground: React.CSSProperties["color"];
  }
}

export const createAppTheme = (): Theme => {
  const theme = createTheme({
    palette: {
      darkBackground: "#f4f6f8",
      // primary: {
      //   main: "#1b67b4",
      // },
      // secondary: {
      //   main: "#fa7e17",
      // },
    },
  });

  return theme;
};
