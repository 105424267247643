import React from "react";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";

export const BasicLayout: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "darkBackground",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
        <NavLink to="/">Abiliti Policy</NavLink>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
