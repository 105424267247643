import { atomWithStorage } from "jotai/vanilla/utils";

export type PolicyViewMode = "grid" | "list";

export type UiSettings = {
  policyView?: PolicyViewMode;
  navBarClosed?: boolean;
};

export const uiAtom = atomWithStorage<UiSettings>("currentUi", {});
