import React from "react";
import { apiUrl } from "../services/apiClient";

interface State {
  hasError: boolean;
  error?: Error;
  info?: string;
}

const pingUrl = apiUrl + "/Engine/info";
const desc =
  "Abiliti Policy v." +
  process.env.REACT_APP_VERSION +
  " ENV: " +
  process.env.NODE_ENV +
  " AUTH:" +
  process.env.REACT_APP_API_AUTH_URL +
  " API: " +
  apiUrl;

class AppHealth extends React.Component<unknown, State> {
  constructor(props: unknown) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  public componentDidMount() {
    fetch(pingUrl)
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      .then((res) => {
        if (res.ok) {
          return res.text();
        }
        this.setState({
          hasError: true,
          info: "API error [" + res.statusText + "]",
        });
        throw new Error("Network response was not ok.");
      })
      .then((text) => this.setState({ info: text }))
      .catch((error) => {
        console.error("error", error);
      });
  }

  render() {
    if (this.state.hasError) {
      return "Unhealthy - " + this.state.info + " " + desc;
    }
    if (!this.state.info) {
      return "Unhealthy - api" + desc;
    }

    return " Healthy " + desc + " response: " + this.state.info;
  }
}

export default AppHealth;
