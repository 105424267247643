import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  EntityOverride,
  EntityType,
  NEW_ENTITY_CODE,
  getEntityRoute,
  queryFilter,
} from "../../features/core/entities";
import { useNavigate } from "react-router-dom";

export const useCreateEntity = () => {
  const navigate = useNavigate();
  const [type, setType] = React.useState<EntityType>();
  const [override, setOverride] = React.useState<EntityOverride>();

  const openCreateEntityDialog = (entityType: EntityType) => {
    setType(entityType);
    setOverride({});
  };

  const handleClose = () => {
    setType(undefined);
  };

  const handleCreate = () => {
    navigate(
      getEntityRoute(type) + "/" + NEW_ENTITY_CODE + "?" + queryFilter(override)
    );
    handleClose();
  };

  const renderCreateEntityDialog = () => {
    return (
      <Dialog fullWidth maxWidth="md" open={!!type} onClose={handleClose}>
        <DialogTitle>Create New {type}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select baseline, keep empty for all options
          </DialogContentText>
          <Box sx={{ py: 2, display: "flex" }}></Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            Create {type}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return { openCreateEntityDialog, renderCreateEntityDialog };
};
