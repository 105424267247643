import React from "react";
import { Box } from "@mui/material";
import MetricCard from "./MetricCard";
import {
  absenceReasonAtom,
  policyAtom,
  questionAtom,
} from "../../features/policies/policy.state";
import { useAtom } from "jotai";
import { companyAtom } from "../../features/clients/client.state";
import { RouteTable } from "../../features/utils/route-paths";

const Dashboard = () => {
  const [policies] = useAtom(policyAtom);
  const [companies] = useAtom(companyAtom);
  const [questions] = useAtom(questionAtom);
  const [absenceReasons] = useAtom(absenceReasonAtom);
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, p: 2 }}>
      <MetricCard title="Clients" value={companies.length} />
      <MetricCard
        title="Policies"
        value={policies.length}
        to={RouteTable.policies}
      />
      <MetricCard
        title="Absence Reasons"
        value={absenceReasons.length}
        to={RouteTable.absenceReasons}
      />
      <MetricCard
        title="Questions"
        value={questions.length}
        to={RouteTable.questions}
      />
    </Box>
  );
};

export default Dashboard;
