import { atomWithDefault } from "jotai/vanilla/utils";
import { loadUserAccount, unauthorizedSession } from "./auth.service";

export const sessionAtom = atomWithDefault(async (get) => {
  return unauthorizedSession;
});

export const accountAtom = atomWithDefault(async (get) => {
  return await loadUserAccount();
});
