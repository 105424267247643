import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import ErrorBoundary from "./contexts/ErrorBoundary";
import { createAppTheme } from "./ui/theme";
import AppGlobalStyles from "./components/AppGlobalStyles";
import { AuthProvider } from "./contexts/AuthContext";
import { DialogProvider } from "./contexts/DialogContext";

function App() {
  const theme = createAppTheme();

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider dense maxSnack={3}>
          <ErrorBoundary>
            <BrowserRouter>
              <AuthProvider>
                <DialogProvider>
                  <CssBaseline />
                  <AppGlobalStyles />
                  <AppRoutes />
                </DialogProvider>
              </AuthProvider>
            </BrowserRouter>
          </ErrorBoundary>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
