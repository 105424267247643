import React from "react";
import { useAuthContext } from "../../contexts/AuthContext";

export const SilentRenewCallback = () => {
  const auth = useAuthContext();
  React.useEffect(() => {
    console.log("SilentRenewCallback: useEffect");
    auth?.signinSilentCallback();
  });

  return <div>renewed</div>;
};
