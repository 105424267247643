import { atomWithDefault } from "jotai/utils";
import { loadEntities } from "../core/entity.service";
import { AbsenceReason } from "./absenceReasons";
import { Policy } from "./policy";
import { PolicyQuestion } from "./question";

export const POLICY_ENDPOINT = "Policy";
export const ABSENCE_REASON_ENDPOINT = "AbsenceReason";
export const QUESTION_ENDPOINT = "Question";

export const policyAtom = atomWithDefault(
  async () => (await loadEntities(POLICY_ENDPOINT)) as Policy[]
);

export const absenceReasonAtom = atomWithDefault(
  async () => (await loadEntities(ABSENCE_REASON_ENDPOINT)) as AbsenceReason[]
);

export const questionAtom = atomWithDefault(
  async () => (await loadEntities(QUESTION_ENDPOINT)) as PolicyQuestion[]
);
