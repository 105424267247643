import { useOutletContext } from "react-router-dom";

export type LayoutContextType = {
  title?: string;
  setTitle?: (title: string) => void;
  titleOverride?: string;
  setTitleOverride?: (title: string) => void;
};

export const useLayoutContext = () => {
  return useOutletContext<LayoutContextType>();
};
