import { User } from "oidc-client-ts";
import apiClient from "../../services/apiClient";
import { authServerURL } from "./auth.config";

export type AppSession = {
  loggedIn: boolean;
  user?: {
    accessToken: string;
    idToken: string;
  };
};

export interface AuthContextService {
  signinRedirect?: () => void;
  signinRedirectCallback?: () => Promise<User>;
  logout?: () => void;
  signoutRedirectCallback?: () => void;
  signinSilentCallback?: () => void;
  // createSigninRequest?: () => void;
  // getIdToken?: () => string;
  // setSessionInfo?: (authResult: AuthResult) => void;
}

export const unauthorizedSession: AppSession = { loggedIn: false };

export type UserAccount = {
  userId: number;
  name: string;
  userName: string;
  givenName: string;
  familyName: string;
  email: string;
  locale: string;
  employeeNumber: string;
  clientCode: string;
  accessType: string;
  privileges: string[];
  roles: string[];
  companyName: string;
  managedCompanies: string[];
};

export const getAccessToken = () => {
  return sessionStorage.getItem("access_token");
};

export const loadUserAccount = async (): Promise<UserAccount> => {
  try {
    if (!getAccessToken()) return null;
    const response = await apiClient.get<UserAccount>(
      authServerURL + `/api/v1/accountinfo`
    );
    return response?.data;
  } catch (err) {
    console.error(err);
  }
};
