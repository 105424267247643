import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

interface LoadingScreenProps {
  fullScreen?: boolean;
  title?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  fullScreen,
  title,
}: LoadingScreenProps) => {
  const sx = fullScreen
    ? {
        left: 0,
        top: 0,
        position: "fixed",
        width: "100%",
      }
    : {};
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2000,
        padding: 3,
        backgroundColor: "background.default",
        ...sx,
      }}
    >
      <Box sx={{ width: "90%" }}>
        <Typography variant="caption">{title}</Typography>
        <LinearProgress sx={{ height: 8, borderRadius: 5 }} />
      </Box>
    </Box>
  );
};

export default LoadingScreen;
