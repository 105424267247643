import React from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";

import { Outlet, useNavigate } from "react-router-dom";
import ErrorBoundary from "../../contexts/ErrorBoundary";
import useIsMobile from "../../ui/useIsMobile";
import NavBar from "./NavBar";
import { accountAtom } from "../../features/auth/account.state";
import { AppContext } from "../../contexts/app.data";
import { uiAtom } from "../../features/settings/uiState";
import { useAtom } from "jotai";
import { useAuthContext } from "../../contexts/AuthContext";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Layout = () => {
  const isMobile = useIsMobile();

  const [uiSettings, setUiSettings] = useAtom(uiAtom);
  const [account] = useAtom(accountAtom);

  const auth = useAuthContext();

  const [open, setOpen] = React.useState(
    !isMobile && !uiSettings?.navBarClosed
  );

  const toggleNavBar = () => {
    const shouldClose = open;
    setUiSettings((s) => ({ ...s, navBarClosed: shouldClose }));
    setOpen(!shouldClose);
  };

  const [title, setTitle] = React.useState(AppContext.appName);
  const [titleOverride, setTitleOverride] = React.useState<string>();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const navigate = useNavigate();

  const handleLogout = React.useCallback(() => {
    // const s = logout();
    // setSession(s);
    auth?.logout();
    navigate("/login");
  }, [auth, navigate]);

  React.useEffect(() => {
    document.title = (titleOverride ?? title) + " | " + AppContext.appName;
  }, [title, titleOverride]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        overflowX: "auto",
      }}
    >
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleNavBar}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {titleOverride ?? title}
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setAnchorElUser(event.currentTarget);
                }}
                sx={{ p: 0 }}
              >
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => {
                setAnchorElUser(null);
              }}
            >
              <MenuItem>
                <Typography>
                  Welcome, <b>{account?.name}</b>
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <NavBar
        toggleNavBar={toggleNavBar}
        openNavBar={open}
        width={drawerWidth}
      />

      <Box
        component="main"
        sx={{
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: "1 1 auto",
            width: "100%",
            p: 2,
          }}
        >
          <Toolbar />
          <ErrorBoundary>
            <Outlet
              context={{ title, setTitle, titleOverride, setTitleOverride }}
            />
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
