import React from "react";
import LoadingScreen from "../../components/LoadingScreen";
import { useAuthContext } from "../../contexts/AuthContext";

export const SigninCallback = () => {
  const auth = useAuthContext();

  React.useEffect(() => {
    console.log("SigninCallback: useEffect");

    auth
      ?.signinRedirectCallback()
      .then((user) => {})
      .catch((err) => {
        console.error(err.message);
      });
  });

  return <LoadingScreen fullScreen title="Signing in" />;
};
