import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <h1>Page Not Found</h1>
      <Typography sx={{ my: 4 }}>
        Sorry, but we can&apos;t find the page you are looking for
      </Typography>
      <Button variant="outlined" component={NavLink} to="/">
        Back to home
      </Button>
    </Box>
  );
};

export default NotFound;
