import React from "react";
import LoadingScreen from "../../components/LoadingScreen";
import { useAuthContext } from "../../contexts/AuthContext";

export const SignoutCallback = () => {
  const auth = useAuthContext();

  React.useEffect(() => {
    auth?.signoutRedirectCallback();
  });

  return <LoadingScreen fullScreen title="Signing out" />;
};
