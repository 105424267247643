import axios from "axios";
import { getAccessToken } from "../features/auth/auth.service";

export const apiUrl = process.env.REACT_APP_API_URL + "/api/v1";

const instance = axios.create({
  baseURL: apiUrl,
});

instance.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      //sessionStorage.clear();
      if (window.location.pathname !== "/login") {
        sessionStorage.clear();
        window.location.href = "/login";
      }
    } else {
      console.error(error);
      return Promise.reject(error);
    }
  }
);

export default instance;
