import { LocaleText } from "../utils/locale";
import { RouteTable } from "../utils/route-paths";

export const NEW_ENTITY_CODE = "new";

export const POLICY_CODE_FIELD = "policyCode";
export const ABSENCE_REASON_CODE_FIELD = "absenceReasonCode";
export const QUESTION_CODE_FIELD = "questionCode";

export type EntityType = "Policy" | "Absence Reason" | "Question";

export type EntityOverrideType = "company" | "state";

export type EntityOverrideEntry = {
  name: EntityOverrideType;
  values: string[];
};

export type EntityOverride = {
  override?: {
    company?: string[];
    state?: string[];
  };
  effectiveDates?: string[];
  asOfDate?: string;
};

export type Entity = {
  description?: LocaleText[];
  dateEffective?: string;
  clientCode?: string;
  isActive?: boolean;
};

export interface ListEntity extends Entity {
  overrides?: EntityOverride[];
  isOverrideSpecific?: boolean;
  allEffectiveDates?: string[];
}

export interface EntityInstance extends Entity {
  overrides?: EntityOverride;
}

export const getOverrideString = (
  override: EntityOverride,
  withKey = false
) => {
  if (isEmptyOverride(override)) return "";
  return (
    Object.entries(override.override)
      .filter(([, v]) => v?.length)
      .map(([k, v]) => `${withKey ? k + ": " : ""}${v.join(", ")}`)
      .join(" / ") ?? ""
  );
};

export const getOverridePairs = (
  overrides?: EntityOverride[],
  withKey = false
) => {
  if (!overrides?.length) return [];
  return overrides.map((o) => getOverrideString(o, withKey));
};

export const stringifyOverrides = (
  overrides?: EntityOverride[],
  withKey = false
) => {
  if (!overrides?.length) return "";
  return getOverridePairs(overrides, withKey).join("; ");
};

// export const getOverrideTerms = (overrides?: EntityOverride[]) => {
//   if (!overrides?.length) return [];
//   return overrides.map((o) => Object.values(o).map((v) => v)).flat();
// };

export const getOverrideValues = (
  override: EntityOverride,
  name: EntityOverrideType
): string[] => {
  return override?.override?.[name] ?? [];
};

// export const getOverrideEffectiveDate = (override: EntityOverride) => {
//   return override?.effectiveDates?.[0];
// };

export const isEmptyOverride = (override: EntityOverride) => {
  return (
    !override?.override ||
    (!override?.override?.company?.length && !override?.override?.state?.length)
  );
};

export const mergeOverride = (
  override: EntityOverride,
  name: EntityOverrideType,
  values?: string[]
) => {
  const newOverride: EntityOverride = {
    override: {},
    ...override,
  };
  if (values?.length) {
    newOverride.override[name] = values;
  } else {
    delete newOverride.override[name];
  }
  return newOverride;
};

export const queryFilter = (override?: EntityOverride) => {
  if (!override) return "";
  const companyQuery = getOverrideValues(override, "company")
    .map((c) => `clientCode=${c}`)
    .join("&");
  const stateQuery = getOverrideValues(override, "state")
    .map((s) => `state=${s}`)
    .join("&");
  const d = override?.asOfDate;
  return (
    companyQuery +
    (companyQuery && stateQuery ? "&" : "") +
    stateQuery +
    (d ? `&asOfDate=${d}` : "") + 
    (d ? `&effectiveDate=${d}` : "")
  );
};

export const areEqualOverrides = (
  override1: EntityOverride,
  override2: EntityOverride
) => {
  return getOverrideString(override1) === getOverrideString(override2);
};

export const getEntityRoute = (type: EntityType) => {
  switch (type) {
    case "Question":
      return RouteTable.questions;
    case "Policy":
      return RouteTable.policies;
    case "Absence Reason":
      return RouteTable.absenceReasons;
  }
  return "/";
};
