import React from "react";
import type { FC, ReactNode } from "react";
import { sessionAtom } from "../features/auth/account.state";
import { useAtom } from "jotai";
import { useAuthContext } from "./AuthContext";
import LoadingScreen from "../components/LoadingScreen";
import { Navigate, useLocation } from "react-router-dom";

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }: AuthGuardProps) => {
  const [session] = useAtom(sessionAtom);
  const auth = useAuthContext();
  const location = useLocation();

  if (!session.loggedIn) {
    if (location.pathname === "/") {
      return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
      auth?.signinRedirect();
      return <LoadingScreen fullScreen title="Signing in" />;
    }
  }
  return <>{children}</>;
};

export default AuthGuard;
