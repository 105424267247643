import React from "react";
import { GlobalStyles } from "@mui/material";
import type { FC } from "react";
const AppGlobalStyles: FC = () => (
  <GlobalStyles
    styles={{
      html: {
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    }}
  />
);

export default AppGlobalStyles;
