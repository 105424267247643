import React from "react";
import { Box } from "@mui/material";
import { useLayoutContext } from "./useLayout";

export const Page = ({ title, children }: { title: string; children }) => {
  const context = useLayoutContext();

  React.useEffect(() => {
    context?.setTitle(title);
    context?.setTitleOverride(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <Box
      sx={{
        overflow: "auto",
        backgroundColor: "darkBackground",
        height: "100%",
        width: "100%",
      }}
    >
      {/* <Typography variant="h3" color="textPrimary" mb={3} mt={1}>
        {title}
      </Typography> */}
      {children}
    </Box>
  );
};
