export const authServerURL = process.env.REACT_APP_API_AUTH_URL;

export const IDENTITY_CONFIG = {
  authority: authServerURL, //(string): The URL of the OIDC provider.
  client_id: process.env.REACT_APP_API_AUTH_CLIENT_ID, //THIS WILL BE SET IN WEB CONFIRUATION PROVIDER  //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: window.location.origin + "/signin-oidc", //The URI of your client application to receive a response from the OIDC provider.
  //login: URL_CONFIG.authServerURL + "/login",
  silentRequestTimeout: 100000,
  automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: window.location.origin + "/silentrenew", //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: window.location.origin + "/login", // (string): The OIDC post-logout redirect URI.
  //audience: "https://example.com", //is there a way to specific the audience when making the jwt
  response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  //grantType: "password",
  scope: "openid abiliti_policy", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: "id_token access_token",
  revokeAccessTokenOnSignout: true,
  accessTokenExpiringNotificationTime: 120,
};

export const METADATA_OIDC = {
  issuer: authServerURL,
  jwks_uri: authServerURL + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: authServerURL + "/connect/authorize",
  token_endpoint: authServerURL + "/connect/token",
  userinfo_endpoint: authServerURL + "/connect/userinfo",
  end_session_endpoint: authServerURL + "/connect/endsession",
  check_session_iframe: authServerURL + "/connect/checksession",
  //revocation_endpoint: URL_CONFIG.authServerURL + "/connect/revocation",
};
