import React from "react";
import type { FC } from "react";
import { useLocation, matchPath, Link as RouterLink } from "react-router-dom";

import {
  Box,
  Divider,
  Drawer,
  List,
  ListSubheader,
  IconButton,
  Toolbar,
} from "@mui/material";

import BarChartIcon from "@mui/icons-material/BarChart";
import CategoryIcon from "@mui/icons-material/Category";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ScienceIcon from "@mui/icons-material/Science";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from "@mui/icons-material/Settings";

import NavItem from "./NavItem";
import { AppContext } from "../../contexts/app.data";
import { RouteTable } from "../../features/utils/route-paths";

interface Item {
  href?: string;
  icon?;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        icon: BarChartIcon,
        href: "/",
      },
      {
        title: "Absence Reasons",
        icon: FormatListBulletedIcon,
        href: RouteTable.absenceReasons,
      },
      {
        title: "Policies",
        icon: CategoryIcon,
        href: RouteTable.policies,
        // items: [
        //   {
        //     title: "Absence Reasons",
        //     href: RouteTable.absenceReasons,
        //   },
        //   {
        //     title: "Questions",
        //     href: "/policies/questions",
        //   },
        // ],
      },
      {
        title: "Questions",
        icon: HelpOutlineIcon,
        href: "/policies/questions",
      },
      {
        title: "Tests",
        icon: ScienceIcon,
        href: "/tests",
      },
      {
        title: "Settings",
        icon: SettingsIcon,
        href: "/settings",
      },
    ],
  },
];

interface NavBarProps {
  toggleNavBar: () => void;
  openNavBar: boolean;
  width: number;
}

const NavBar: FC<NavBarProps> = ({
  toggleNavBar,
  openNavBar,
  width,
}: NavBarProps) => {
  const location = useLocation();

  const renderNavItems = React.useCallback(
    ({ items, depth = 0 }: { items: Item[]; depth?: number }) => {
      return (
        <List disablePadding>
          {items.map((item) => {
            const open = matchPath(item.href + "/*", location.pathname);
            const selected = matchPath(item.href, location.pathname);

            return (
              <NavItem
                depth={depth}
                icon={item.icon}
                href={item.href}
                key={item.title + depth}
                open={!!open}
                selected={!!selected}
                title={item.title}
                isOpenNav={openNavBar}
              >
                {item.items?.length > 0 &&
                  renderNavItems({
                    items: item.items,
                    depth: depth + 1,
                  })}
              </NavItem>
            );
          })}
        </List>
      );
    },
    [location.pathname, openNavBar]
  );

  const barItems = React.useMemo(() => {
    return sections.map((section) => (
      <List
        key={section.subheader}
        subheader={
          <ListSubheader disableGutters disableSticky>
            {section.subheader}
          </ListSubheader>
        }
      >
        {renderNavItems({
          items: section.items,
        })}
      </List>
    ));
  }, [renderNavItems]);

  return (
    <Drawer
      variant="permanent"
      sx={(theme) => ({
        "& .MuiDrawer-paper": {
          position: "relative",
          whiteSpace: "nowrap",
          width,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxSizing: "border-box",
          ...(!openNavBar && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
              width: theme.spacing(9),
            },
          }),
        },
      })}
    >
      <Box height="100%" display="flex" flexDirection="column">
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          <RouterLink to="/">
            {AppContext.appName}
            {/* <img
              alt="Logo"
              src="/images/app-logo.png"
              style={{ maxHeight: 24 }}
            /> */}
          </RouterLink>
          <IconButton onClick={toggleNavBar}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Box p={2}>{barItems}</Box>
      </Box>
    </Drawer>
  );
};

export default NavBar;
