import React from "react";
import { Box, Button } from "@mui/material";

import { AppContext } from "../../contexts/app.data";
import { useAuthContext } from "../../contexts/AuthContext";

const Login = () => {
  const auth = useAuthContext();

  const handleLogin = () => {
    auth?.signinRedirect();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>{AppContext.appName}</h1>
      <Box sx={{ mt: 4 }}>
        <Button size="large" variant="contained" onClick={() => handleLogin()}>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
