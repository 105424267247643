import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface MetricCardProps {
  title: string;
  info?: string;
  unit?: string;
  value: number;
  to?: string;
}

const MetricCard = ({ title, info, unit, value, to }: MetricCardProps) => {
  return (
    <Card
      sx={{
        padding: 3,
        minWidth: 200,
        textDecoration: "none",
      }}
      component={to ? Link : null}
      to={to}
    >
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Typography
          component="h1"
          gutterBottom
          variant="overline"
          color="textSecondary"
          sx={{ textAlign: "center", width: "100%" }}
        >
          {title}
        </Typography>
        <Typography variant="caption" color="textSecondary" mt={1}>
          {info}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography
          variant="h4"
          color="textPrimary"
          sx={{ textAlign: "center", width: "100%" }}
        >
          {value} {unit}
        </Typography>
      </Box>
    </Card>
  );
};

export default MetricCard;
