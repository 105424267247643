import apiClient from "../../services/apiClient";
import {
  Entity,
  EntityOverride,
  NEW_ENTITY_CODE,
  queryFilter,
} from "./entities";

export const searchEntity = async <T extends Entity>(
  endpoint: string,
  codeField: string,
  keyword: string
): Promise<string[]> => {
  const response = await apiClient.get<{
    results: T[];
    totalCount: number;
  }>(`/${endpoint}/search?keyword=${keyword}`);
  const codes = response?.data.results?.map((p) => p[codeField]) ?? [];
  return codes;
};

export const loadEntities = async <T extends Entity>(
  endpoint: string
): Promise<T[]> => {
  const response = await apiClient.get<T[]>("/" + endpoint);
  return response.data || [];
};

export const loadEntity = async <T extends Entity>(
  endpoint: string,
  code: string,
  override?: EntityOverride
): Promise<T> => {
  const arResponse = await apiClient.get<T>(
    `/${endpoint}/${code}?${queryFilter(override)}`
  );
  return arResponse.data;
};

export const exportEntity = async (endpoint: string, code: string) => {
  const arResponse = await apiClient.get(`/${endpoint}/${code}/export`);
  return arResponse.data;
};

export const saveEntity = async <T extends Entity>(
  endpoint: string,
  code: string,
  entity: T,
  override: EntityOverride
) => {
  const response =
    code === NEW_ENTITY_CODE
      ? await apiClient.post<T>(`/${endpoint}?${queryFilter(override)}`, entity)
      : await apiClient.put<T>(
          `/${endpoint}/${code}?${queryFilter(override)}`,
          entity
        );
  return response.data;
};

export const deleteEntity = async <T extends Entity>(
  endpoint: string,
  code: string,
  override?: EntityOverride
) => {
  const response = await apiClient.delete<T>(
    `/${endpoint}/${code}?${queryFilter(override)}`
  );
  return response.data;
};
