import React, { ReactNode, createContext, Suspense } from "react";
import { EntityType } from "../features/core/entities";
import { useCreateEntity } from "../containers/policies/CreateEntity";
import LoadingScreen from "../components/LoadingScreen";

interface DialogProviderProps {
  children?: ReactNode;
}

interface DialogContextProps {
  openCreateEntityDialog: (type: EntityType) => void;
}

export const DialogContext = createContext<DialogContextProps>(null);

export const DialogProvider: React.FC<DialogProviderProps> = ({
  children,
}: DialogProviderProps) => {
  const { renderCreateEntityDialog, openCreateEntityDialog } =
    useCreateEntity();

  return (
    <DialogContext.Provider
      value={{
        openCreateEntityDialog,
      }}
    >
      {children}
      <Suspense fallback={<LoadingScreen title="loading data" />}>
        {renderCreateEntityDialog()}
      </Suspense>
    </DialogContext.Provider>
  );
};

export const useDialogs = () => {
  return React.useContext(DialogContext);
};
