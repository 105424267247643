import React from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { sessionAtom } from "../features/auth/account.state";
import { useAtom } from "jotai";

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }: GuestGuardProps) => {
  const [session] = useAtom(sessionAtom);
  if (session.loggedIn) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export default GuestGuard;
