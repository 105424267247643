import React, { ReactNode, createContext } from "react";
import { User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { useAtom } from "jotai";
import { accountAtom, sessionAtom } from "../features/auth/account.state";

import { IDENTITY_CONFIG, METADATA_OIDC } from "../features/auth/auth.config";
import {
  AppSession,
  AuthContextService,
  loadUserAccount,
} from "../features/auth/auth.service";
import { useNavigate } from "react-router-dom";

export const signinCallbackPath = "signin-oidc";

interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthContext = createContext<AuthContextService>(null);

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps) => {
  const [, setSession] = useAtom(sessionAtom);
  const [, setAccount] = useAtom(accountAtom);

  const navigate = useNavigate();

  const userManager = new UserManager({
    ...IDENTITY_CONFIG,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    metadata: {
      ...METADATA_OIDC,
    },
  });

  const setUser = async (user: User) => {
    const s: AppSession = {
      loggedIn: true,
      user: {
        accessToken: user.access_token,
        idToken: user.id_token,
      },
    };
    setSession(s);
    sessionStorage.setItem("id_token", s.user.idToken);
    sessionStorage.setItem("access_token", s.user.accessToken);

    const account = await loadUserAccount();
    setAccount(account);
  };

  userManager.events.addUserLoaded(async (user) => {
    setUser(user);

    if (window.location.href.indexOf(signinCallbackPath) !== -1) {
      navigateToScreen();
    }
  });

  userManager.events.addUserSignedOut(async () => {
    await signinRedirect();
  });

  userManager.events.addSilentRenewError(async (e) => {
    console.log("silent renew error", e.message);
    if (e.message === "login_required") {
      await signinRedirect();
    }
  });

  userManager.events.addAccessTokenExpiring(async () => {
    try {
      const user = await userManager.getUser();
      if (user) {
        await userManager.signinSilent();
        // const user = await userManager.signinSilent();
        // setUser(user);
      }
    } catch (error) {
      console.log("silent sign error", error);
    }
  });

  userManager.events.addAccessTokenExpired(async () => {
    try {
      await userManager.removeUser();
      await signinRedirect();
    } catch (error) {
      console.error("access token expiration error", error);
    }
  });

  const signinRedirectCallback = () => {
    return userManager.signinRedirectCallback();
  };

  const signinRedirect = async () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    await userManager.signinRedirect({});
  };

  const navigateToScreen = () => {
    const redirectUri = localStorage.getItem("redirectUri") ?? "/";
    navigate(redirectUri);
  };

  const signinSilentCallback = async () => {
    return userManager.signinSilentCallback();
  };

  //   const createSigninRequest = async () => {
  //     return userManager.createSigninRequest();
  //   };

  const clearSession = () => {
    setSession(null);
    setAccount(null);
    localStorage.clear();
    sessionStorage.clear();
  };

  const logout = async () => {
    await userManager.signoutRedirect({
      id_token_hint: sessionStorage.getItem("id_token"),
      post_logout_redirect_uri: IDENTITY_CONFIG.post_logout_redirect_uri,
    });
    clearSession();
    await userManager.clearStaleState();
  };

  const signoutRedirectCallback = async () => {
    await userManager.signoutRedirectCallback().then(() => {
      clearSession();
      window.location.replace(IDENTITY_CONFIG.post_logout_redirect_uri);
    });
    await userManager.clearStaleState();
  };

  return (
    <AuthContext.Provider
      value={{
        signinRedirect,
        signinRedirectCallback,
        logout,
        signoutRedirectCallback,
        signinSilentCallback,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext: () => AuthContextService = () => {
  return React.useContext(AuthContext);
};
